<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation']" :submit_preprocessing="submit_preprocessing" ref="list" :rowKey="'id'">
			<template slot="left_btn">
				<div class="left_btn_box">
					<a-button @click="add()" type="primary">添加标准</a-button>
					<!-- <p>
						<a-icon type="info-circle" />添加差旅标准前，请先前往酒店分级设置设置酒店级别
					</p> -->
				</div>
			</template>
			<template slot="right_btn">
				<a-button @click="add_company()">设置同行标准</a-button>
			</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="edit(data.record)">编辑</a>
				</span>
				<a-divider type="vertical" v-if='data.record.flag' />
				<a-popconfirm title="您确定要删除吗?" v-if='data.record.flag' @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
			</template>
		</TableList>
		<EditPop @handleSubmit="submit_company" :width="850"  class='edit_pop1' :form_data="form_company"
			:visible.sync="visible_data_company">
			<template slot="clock_tips">
				<p class="tips_p info_tips">
					<a-icon type="info-circle" class='info_icon' />若勾选此选项，员工入住/离开酒店都需要定位打卡，系统还会验证用户是否在酒店范围内打卡
				</p>
			</template>
			<template slot="free_person" class="free_person">
				<TransferTree  class='free_tree'  @change="TransferTreeChange" :treeData.sync="treeData"  :value.sync="treeDataValue" />
				<p>
					<a-icon type="info-circle" class='info_icon' />若为免签批人员，预定酒店的时候不强制同住，且酒店预定标准没有限制。
				</p>
			</template>

		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";
	import {
		authInfo
	} from '@/api/system'
	import TransferTree from "@/components/TransferTree";
	import {
		tripStandardList,
		delTripStandard,
		getTogetherStandard,
		setTogetherStandard,
		getUserSelect,
		getUserToRole
	} from "@/api/evection";
	import {
		getUserList
	} from "@/api/personnel";

	const columns = [{
			title: "ID",
			dataIndex: "id"
		},
		{
			title: "差旅标准名称",
			dataIndex: "title"
		},
		{
			title: "适用人数",
			dataIndex: "user_num"
		},
		{
			title: "标准类型",
			dataIndex: "type_name"
		},
		{
			title: "最后更新时间",
			dataIndex: "update_time"
		},

		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];
	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser,
			TransferTree
		},
		data() {
			return {
				treeData: [],
				treeDataValue: [],
				all_person: [],
				default_person: [],
				visible_data_company: false,
				form_company: {
					title: "设置同行标准",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "radio",
							name: "live_together",
							title: "同住酒店标准",
							options: {
								rules: [{
									required: false,
									message: ""
								}],
								initialValue: ""
							},
							list: [{
									key: "high",
									value: "按同住人高标准执行"
								},
								{
									key: "low",
									value: "按同住人低标准执行"
								},
							]
						},
						{
							type: "radio",
							name: "walk_together",
							title: "同行交通标准",
							options: {
								rules: [{
									required: false,
									message: ""
								}],
								initialValue: ""
							},
							list: [{
									key: "high",
									value: "按同行人高标准执行"
								},
								{
									key: "low",
									value: "按同行人低标准执行"
								},
							]
						},
						// {
						// 	type: "radio",
						// 	name: "clock_in",
						// 	title: "入住/离店打卡",
						// 	options: {
						// 		rules: [{
						// 			required: false,
						// 			message: ""
						// 		}],
						// 		initialValue: ""
						// 	},
						// 	list: [{
						// 			key: "yes",
						// 			value: "是"
						// 		},
						// 		{
						// 			key: "no",
						// 			value: "否"
						// 		},
						// 	]
						// },
						// {
						// 	type: "slot",
						// 	name: "clock_tips",
							
						// },
						// {
						// 	type: "slot",
						// 	name: "free_person",
						// 	title:'免签批人员',
						// 	placeholder: "点击添加免签批人员",
							
						// },


					]
				},
				config: this.$config,
				get_table_list: tripStandardList,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id']
				},
				columns,
				selectedRowKeys: [],
				loading: false,
				visible: false,
				rule_user:'',
				form_data_seo: {
					list: [

						{
							type: "text",
							name: "keyword",
							title: "标准名称",
							placeholder: "差旅标准名称",
							options: {}
						},
						{
							type: 'select',
							title: '标准类型',
							name: 'type',
							options: {},
							list: [{
									key: 'stay',
									value: "住宿标准"
								},
								{
									key: 'traffic',
									value: "交通标准"
								},
								{
									key: 'subsidy',
									value: "补贴标准"
								},
							]
						},
						{
							type: "range_date",
							name: "range_date",
							title: "最后更新时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo
				},
				form_data: {
					title: '添加酒店级别',
					list: [{
							type: 'text',
							name: 'goods_title',
							title: '级别名称',
							options: {
								rules: [{
									required: true,
									message: "请输入级别名称"
								}]
							},
						},
						{
							type: 'number',
							name: 'goods_price',
							title: '价格区间',
							options: {
								rules: [{
									required: true,
									message: "请输入价格区间"
								}]
							},
						},
					]
				}
			};
		},


		async created() {
			this.get_standard();
			// this.get_role()
		},
		methods: {
			change_tree(data) {
				
			},
			get_role() {
				getUserToRole().then(res => {
					this.treeData = this.$method.get_user_department_list(res.data.role);
				})

			},
			handleChange() {},
			TransferTreeChange(value) {
				this.rule_user =  value.join(',')
			},
			get_standard() {
				// this.get_user()
				getTogetherStandard().then(res => {
					this.form_company.list.forEach(item => {
						if (item.name == 'live_together') {
							item.options.initialValue = res.data.list.live_together
						}
						if (item.name == 'walk_together') {
							item.options.initialValue = res.data.list.walk_together
						}
						// if (item.name == 'clock_in') {
						// 	item.options.initialValue = res.data.list.clock_in
						// }
					})
					// let treeDataValue = [];
					// let role_data = []
					// res.data.list.user.map(io => {
					// 	treeDataValue.push("user_" + io.id)
					// 	io.key = "user_" + io.id;
					// 	io.title = io.username;
					// 	role_data.push(io.key)
					// 	return io;
					// });
					// this.treeDataValue = treeDataValue;
					// this.rule_user = role_data.join(',')
				})
			},
			add_company() {
				this.visible_data_company = true
			},
			submit_company(data) {
				// data.values.user_ids=this.rule_user
				setTogetherStandard({
					data: {
						standard:JSON.stringify(data.values)
					},
					info: true
				}).then(res => {
					this.get_standard();
					this.visible_data_company = false;
					// this.rule_user=''
					// this.get_role()
				})
			},
			del(record) {
				delTripStandard({
					data: {
						id: record.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			add() {
				this.$router.push("/evection/standards_edit");
			},
			edit(key) {
				this.$router.push("/evection/standards_edit?id=" + key.id);
			},
		}
	};
</script>

<style lang="less" >
	@import url("../../assets/less/app.less");

	.left_btn_box {
		flex: inherit;

		p {
			margin: 5px 0px;
		}
	}

	.edit_pop1 {
		.ant-col-19 {
			width: 77%;
		}

		.ant-form-item-label {
			width: 22%;
		}

		.ant-form-item {
			margin-bottom: 0px;
		}

		.tips_p {
			margin-top: -30px;
			margin-left: 175px;
			line-height: 25px;
			width: 70%;
		}
		.info_icon {
			margin-right: 5px;
		}
	}
	.free_title {
		margin-left: 90px;
		color: #000;
	}
	.free_tree {
		margin-left: 80px;
	}
	.info_tips {
		
	}
</style>
